<template>
  <section id="avg-order">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="2" md="2">
          <b-form-radio-group name="radios-btn-default" button-variant="primary" buttons class="btn-block"
            v-model="selectedPlatform" :options="optionsPlatform" @change="changePlatform" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ avgDeliveryTime | formatNumber }} days
                </h2>
                <span>Avg. time</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2"></b-col>
        <b-col xl="2" md="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ avgOrderValue | formatCurrencyNumber }}
                </h2>
                <span>Avg. order value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. delivery time
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-delivery-time" />
              </h4>
              <b-popover target="popover-avg-delivery-time" triggers="hover" placement="bottom">
                <span>The 'Average Delivery Time' section features a line chart that illustrates the average delivery
                  time, measured in days, for each month. This visual representation helps users identify trends and
                  patterns in delivery performance over time. By analyzing the chart, users can easily spot any
                  significant changes in delivery times, such as seasonal variations or the impact of specific events.
                  Additionally, this section provides valuable insights for optimizing logistics and improving overall
                  delivery efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgDeliveryTimeChart" :options="avgDeliveryTimeChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. order value
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-order-value" />
              </h4>
              <b-popover target="popover-avg-order-value" triggers="hover" placement="bottom">
                <span>The 'Average Order Value' section features a line chart that illustrates the average order value
                  for each month. This visual representation helps users identify trends and patterns in customer
                  spending over time. By analyzing the chart, users can easily spot any significant changes in order
                  values, such as seasonal variations or the impact of promotions and sales events. Additionally, this
                  section provides valuable insights for optimizing pricing strategies and improving overall revenue
                  generation.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgOrderValueChart" :options="avgOrderValueChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. delivery time by years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-delivery-time-year" />
              </h4>
              <b-popover target="popover-avg-delivery-time-year" triggers="hover" placement="bottom">
                <span>The 'Average Delivery Time by Years' section features a bar chart that illustrates the average
                  delivery time, measured in days, grouped by years. This visual representation helps users identify
                  long-term trends and patterns in delivery performance. By analyzing the chart, users can easily spot
                  any significant changes in delivery times across different years, such as the impact of operational
                  improvements or external factors. Additionally, this section provides valuable insights for strategic
                  planning and optimizing long-term logistics efficiency.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgDeliveryTimeByYearChart" :options="avgDeliveryTimeByYearChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Avg. order value by years
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-avg-order-value-year" />
              </h4>
              <b-popover target="popover-avg-order-value-year" triggers="hover" placement="bottom">
                <span>The 'Average Order Value by Years' section features a bar chart that illustrates the average order
                  value, grouped by years. This visual representation helps users identify long-term trends and patterns
                  in customer spending. By analyzing the chart, users can easily spot any significant changes in order
                  values across different years, such as the impact of economic conditions or major sales events.
                  Additionally, this section provides valuable insights for strategic planning and optimizing revenue
                  generation over the long term.</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="avgOrderValueByYearChart" :options="avgOrderValueByYearChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BRow,
  BCol,
  BFormRadioGroup,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BRow,
    BCol,
    BFormRadioGroup,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      show: true,
      selectedPlatform: 'allOrders',
      optionsPlatform: [
        { text: 'All Orders', value: 'allOrders' },
        { text: 'Shop Orders', value: 'shopOrders' },
      ],
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      avgDeliveryTime: '',
      avgOrderValue: '',
      queryParams: {},
      avgDeliveryTimeChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgOrderValueChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgDeliveryTimeByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      avgOrderValueByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParams.from_date = dateRange[0];
    this.queryParams.to_date = dateRange[1];

    try {
      await this.getAvgOrder();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    updateChart(data) {
      const avgDeliveryTimeValues = data[0].data.results.map(item => item.avg_delivery_time);
      const avgOrderValueValues = data[0].data.results.map(item => item.avg_order_value);
      this.avgDeliveryTimeChart.xAxis.data = data[0].data.results.map(item => item.month_year);
      this.avgDeliveryTimeChart.series = {
        name: "Avg. delivery time",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          distance: 5,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: avgDeliveryTimeValues,
      };
      this.avgOrderValueChart.xAxis.data = data[0].data.results.map(item => item.month_year);
      this.avgOrderValueChart.series = {
        name: "Avg. order value",
        type: 'line',
        label: {
          show: true,
          position: 'top',
          distance: 5,
          align: 'left',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
        },
        data: avgOrderValueValues,
      };
      this.avgDeliveryTime = (avgDeliveryTimeValues.reduce((a, b) => (a + b)) / avgDeliveryTimeValues.length);
      this.avgOrderValue = (avgOrderValueValues.reduce((a, b) => (a + b)) / avgOrderValueValues.length);
      this.avgDeliveryTimeByYearChart.xAxis.data = data[1].data.results.map(item => item.year);
      this.avgDeliveryTimeByYearChart.series = {
        name: "Avg. delivery time",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[1].data.results.map(item => item.avg_delivery_time),
      };
      this.avgOrderValueByYearChart.xAxis.data = data[1].data.results.map(item => item.year);
      this.avgOrderValueByYearChart.series = {
        name: "Avg. order value",
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[1].data.results.map(item => item.avg_order_value),
      };
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAvgOrder() {
      this.show = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/avg-order-by-month-year/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/avg-order-by-year/`, this.queryParams),
        ]);
        this.updateChart(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async changePlatform() {
      this.queryParams = {};
      const dateRange = this.dateRange.split(' to ');
      if (this.selectedPlatform === 'shopOrders') {
        this.queryParams.platform = 2;
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
      } else {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
      }
      await this.getAvgOrder();

      this.$nextTick(() => {
        this.$refs.avgDeliveryTimeChart.refresh();
        this.$refs.avgOrderValueChart.refresh();
        this.$refs.avgDeliveryTimeByYearChart.refresh();
        this.$refs.avgOrderValueByYearChart.refresh();
      });
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParams.from_date = dateRange[0];
        this.queryParams.to_date = dateRange[1];
        await this.getAvgOrder();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.avgDeliveryTimeChart.refresh();
          this.$refs.avgOrderValueChart.refresh();
          this.$refs.avgDeliveryTimeByYearChart.refresh();
          this.$refs.avgOrderValueByYearChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
